import React, { useEffect, useState } from 'react'
import posed from 'react-pose'

import { DEFAULT_LANG } from 'config/languages'
import trackEvent from 'utils/trackEvent'
import Socials from 'components/SocialsShare'
import Button from 'components/Button'
import FontAwesome from 'components/FontAwesome'
import Markdown from 'components/Markdown'
import Link from 'components/Link'
import SmallText from 'components/SmallText'

import PostHeader from './PostHeader'
import Cover from './Cover'
import { StyledArticle, PDFWrapper, StyledContent, StyledNextPost, StyledDisclosure } from './styles'

export default function PostData({ data, additionalData, nextPost, lang, path }) {
   const langPrefix = lang === DEFAULT_LANG ? '/' : `/${lang}/`
   const [animationStart, setAnimationStart] = useState(false)
   const { postTitle, postCategories, postContent, timeRead, postDate, postAuthors, pdf, cover } = data
   const { authorLabel, disclosures, downloadLabel, readNextLabel } = additionalData
   useEffect(() => {
      setAnimationStart(true)
   }, [])
   return (
      <PosedArticle pose={animationStart ? 'open' : 'exit'}>
         <PostHeader
            title={postTitle}
            timeRead={timeRead}
            date={postDate}
            categories={postCategories}
            authors={postAuthors}
            langPrefix={langPrefix}
            lang={lang}
            authorLabel={authorLabel}
         />
         <Socials pathname={path} title={postTitle} />
         {cover && <Cover {...cover} />}
         {pdf?.file && (
            <PosedInfo className="no-js-visibility">
               <PDFWrapper>
                  <Button
                     onClick={() => {
                        trackEvent('Download PDF Clicked', postTitle)
                     }}
                  >
                     <a href={`https:${pdf.file.url}`} target="_blank" rel="noreferrer">
                        <span>{downloadLabel}</span>
                        <FontAwesome icon={'arrowRight'} />
                     </a>
                  </Button>
               </PDFWrapper>
            </PosedInfo>
         )}
         {postContent?.markdown && (
            <PosedContent>
               <Markdown lang={lang}>{postContent.markdown}</Markdown>
            </PosedContent>
         )}
         {nextPost && (
            <PosedNextPost>
               <span>{readNextLabel}: </span>
               <Link type="internal" url={`${langPrefix}${nextPost.slug}`}>
                  {nextPost.title}
               </Link>
            </PosedNextPost>
         )}
         {disclosures?.markdown && (
            <PosedDisclosure>
               <SmallText content={disclosures.markdown} />
            </PosedDisclosure>
         )}
      </PosedArticle>
   )
}

const PosedArticle = posed(StyledArticle)({
   open: {
      staggerChildren: 65,
   },
   initialPose: 'exit',
})

const PosedInfo = posed.div({
   open: { opacity: 1, y: 0 },
   exit: { opacity: 0, y: 8 },
})

const PosedContent = posed(StyledContent)({
   open: { opacity: 1, y: 0 },
   exit: { opacity: 0, y: 8 },
})

const PosedNextPost = posed(StyledNextPost)({
   open: { opacity: 1, y: 0 },
   exit: { opacity: 0, y: 8 },
})

const PosedDisclosure = posed(StyledDisclosure)({
   open: { opacity: 1, y: 0 },
   exit: { opacity: 0, y: 8 },
})
