import styled from 'styled-components'

import media from 'utils/media'

export const StyledWrapper = styled.div`
   margin-bottom: 24px;
`

export const StyledCategories = styled.ul`
   display: flex;
   align-items: center;
   margin-bottom: 8px;

   li {
      margin-bottom: 0;

      &:not(:last-child)::after {
         content: '•';
         margin: 0 5px;
         color: ${({ theme }) => theme.colors.hotCinnamon};
      }
   }

   a {
      font-family: 'titillium_web';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.hotCinnamon};
   }
`

export const StyledTitle = styled.h1`
   margin-bottom: 30px;
   color: ${({ theme }) => theme.colors.header};
   font-size: 27px;
   line-height: 35px;
   font-weight: 500;
   letter-spacing: -0.5px;

   ${media.greaterThan('md')`
      font-size: 38px;
      line-height: 47px;
      margin-bottom: 24px;
   `};
`

export const StyledAuthors = styled.div`
   display: flex;
   align-items: center;
   margin: 12px 0;

   ${media.greaterThan('md')`
      margin: 0;
   `};

   & > div:first-of-type {
      align-items: center;
      margin-right: 8px;
      display: flex;
   }
`

export const StyledAuthorImg = styled.div`
   width: 38px;
   height: 38px;
   border-radius: 50%;
   overflow: hidden;
   background-color: ${({ theme }) => theme.colors.textColor};
   transform: ${({ n }) => `translateX(${n * -5}px)`};
   position: relative;
   z-index: ${({ n }) => 5 - n};

   img {
      max-width: 100%;
      margin-bottom: 0;
   }
`

export const StyledAuthorName = styled.div`
   text-transform: uppercase;
   font-weight: 300;
   font-size: 16px;
   line-height: 18px;
   color: ${({ theme }) => theme.colors.header};

   strong {
      font-weight: 700;

      a:hover {
         color: ${({ theme }) => theme.colors.hotCinnamon};
      }
   }
`

export const StyledDate = styled.div`
   font-weight: 300;
   font-size: 16px;
   line-height: 14px;

   svg {
      margin-left: 12px;
      color: ${({ theme }) => theme.colors.tabLabel};
   }
`
export const StyledInfo = styled.div`
   ${media.greaterThan('md')`
      display: flex;
      align-items: center;
      justify-content: space-between;
   `};
`
