import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'

import PostData from 'partials/post/PostData'

import { LangContext } from 'context'

const Post = ({ location, pageContext, data }) => {
   const { title, description, nextPost, lang } = pageContext || {}
   const { post, additionalData, header, footer } = data || {}

   return (
      <LangContext.Provider value={{ lang, path: location.pathname }}>
         <Layout
            lang={lang}
            pageTitle={title}
            pageDescription={description}
            location={location}
            header={header}
            footer={footer}
            postData={{ title: post.postTitle, url: post.slug }}
         >
            <PostData
               data={post}
               nextPost={nextPost}
               lang={lang}
               path={location.pathname}
               additionalData={additionalData}
            />
         </Layout>
      </LangContext.Provider>
   )
}

export const postQuery = graphql`
   query postQuery($id: String!, $lang: String!) {
      post: contentfulPost(id: { eq: $id }) {
         id
         node_locale
         postTitle
         slug
         postCategories {
            categoryName
            categoryLabel
         }
         postContent {
            markdown: postContent
         }
         timeRead
         postDate(formatString: "MMMM D, YYYY")
         postAuthors {
            id
            name
            profilePhoto {
               gatsbyImage(height: 100, width: 100)
            }
            socialUrl
         }
         pdf {
            file {
               url
            }
         }
         cover {
            title
            coverType
            cover {
               id
               gatsbyImageData
            }
            coverVideo
            coverPodcast
         }
      }
      additionalData: contentfulPartialPostData(node_locale: { eq: $lang }) {
         authorLabel
         disclosures {
            markdown: disclosures
         }
         downloadLabel
         readNextLabel
      }
      header: contentfulHeader(node_locale: { eq: $lang }) {
         searchPlaceholder
         headerLabel
         newsletterText {
            content: newsletterText
         }
         desktopNav {
            __typename
            ... on ContentfulLink {
               id
               name
               type
               url
               title
            }
         }
         mobileNav {
            id
            title
            type
            url
         }
         smallNav {
            id
            title
            type
            url
         }
      }
      footer: contentfulFooter(node_locale: { eq: $lang }) {
         menu {
            id
            title
            type
            url
         }
         smallMenu {
            id
            title
            type
            url
         }
         textContent {
            markdown: textContent
         }
         copyText {
            markdown: copyText
         }
         copyTitle
      }
   }
`
export default Post
