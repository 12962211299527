import styled from 'styled-components'

import media from 'utils/media'
import chevronRight from 'assets/svg/chevron-right.svg'
import chevronRightDarkMode from 'assets/svg/chevron-right-dark-mode.svg'

export const StyledArticle = styled.article`
   width: 100%;
   padding: 24px 40px 48px;

   ${media.greaterThan('sm')`
      max-width: 540px;
      margin: 0 auto;
      padding: 24px 16px 48px;
   `};

   ${media.greaterThan('md')`
      max-width: 680px;
      padding: 70px 0;
   `};
`

export const PDFWrapper = styled.div`
   margin: 40px 0;

   a {
      svg {
         margin-left: 22px;
         margin-right: 0;
      }
   }
`

export const StyledContent = styled.div`
   margin-top: 24px;

   ${media.greaterThan('md')`
      margin-top: 48px;
   `};

   & > div {
      & > p {
         margin-bottom: 1.75rem;

         & > img {
            margin-top: 1.75rem;
            margin-bottom: 1.75rem;
            width: 100%;
         }

         &:last-of-type {
            margin-bottom: 0;
         }
      }

      & > h2,
      & > h3 {
         font-weight: 700;
         margin-top: 35px;
         margin-bottom: 20px;
      }

      & > h2 {
         font-size: 24px;
         line-height: 31px;
      }

      & > h3 {
         font-size: 20px;
         line-height: 26px;
      }

      a {
         color: ${({ theme }) => theme.colors.hotCinnamon};
         display: inline-block;
         position: relative;

         &::before {
            content: '';
            width: 100%;
            height: 1px;
            background: ${({ theme }) => theme.colors.hotCinnamon};
            position: absolute;
            bottom: 2px;
            opacity: 0;
            transition: ${({ theme }) => theme.transitions.main};
         }

         &:hover {
            &::before {
               opacity: 1;
            }
         }
      }

      & > ul li {
         padding-left: 20px;
         position: relative;

         ${media.greaterThan('md')`
            padding-left: 16px;
         `};

         &:before {
            content: url(${({ theme }) => (theme.darkMode ? chevronRightDarkMode : chevronRight)});
            position: absolute;
            top: -2px;
            left: 0;
            width: 8px;
         }
      }
   }
`

export const StyledNextPost = styled.div`
   margin: 48px 0;

   a {
      text-decoration: underline;

      &:hover {
         color: ${({ theme }) => theme.colors.hotCinnamon};
      }
   }
`

export const StyledDisclosure = styled.div`
   padding-top: 24px;
   border-top: 1px solid ${({ theme }) => theme.colors.textColor};
`
