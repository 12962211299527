import React from 'react'
import posed from 'react-pose'
import { GatsbyImage } from 'gatsby-plugin-image'

import FontAwesome from 'components/FontAwesome'
import Link from 'components/Link'
import defaultAuthorImg from 'assets/img/mcc_defaultAuthor.png'
import { parseLocalDate } from 'utils/helpers'

import {
   StyledWrapper,
   StyledCategories,
   StyledTitle,
   StyledAuthors,
   StyledAuthorImg,
   StyledAuthorName,
   StyledInfo,
   StyledDate,
} from './styles'

export default function PostHeader({ title, timeRead, date, categories, authors, authorLabel, langPrefix, lang }) {
   return (
      <StyledWrapper>
         {Array.isArray(categories) && (
            <PosedCategories>
               {categories.map((category) => (
                  <li key={category.categoryName}>
                     <Link url={`${langPrefix}${category.categoryName}/`} type="internal">
                        {category.categoryName}
                     </Link>
                  </li>
               ))}
            </PosedCategories>
         )}
         <PosedTitle>{title}</PosedTitle>
         <PosedInfo>
            {Array.isArray(authors) ? (
               <StyledAuthors>
                  <div>
                     {authors.map((author, index) => (
                        <StyledAuthorImg key={author.id} n={index}>
                           {author.profilePhoto?.gatsbyImage && (
                              <GatsbyImage image={author.profilePhoto.gatsbyImage} alt={author.name} />
                           )}
                        </StyledAuthorImg>
                     ))}
                  </div>
                  <StyledAuthorName>
                     <span>{authorLabel} </span>
                     {authors.map((author, index) => {
                        if (index > 0) {
                           return (
                              <React.Fragment key={author.id}>
                                 <span> AND </span>
                                 <strong>
                                    {author.socialUrl ? (
                                       <a href={author.socialUrl} target="_blank" rel="noreferrer">
                                          {author.name}
                                       </a>
                                    ) : (
                                       author.name
                                    )}
                                 </strong>
                              </React.Fragment>
                           )
                        }
                        return (
                           <strong key={author.id}>
                              {author.socialUrl ? (
                                 <a href={author.socialUrl} target="_blank" rel="noreferrer">
                                    {author.name}
                                 </a>
                              ) : (
                                 author.name
                              )}
                           </strong>
                        )
                     })}
                  </StyledAuthorName>
               </StyledAuthors>
            ) : (
               <StyledAuthors>
                  <div>
                     <StyledAuthorImg>
                        <img src={defaultAuthorImg} alt="Multicoin capital" />
                     </StyledAuthorImg>
                  </div>
                  <StyledAuthorName>
                     <span>{authorLabel} </span>
                     <strong>Multicoin Capital</strong>
                  </StyledAuthorName>
               </StyledAuthors>
            )}
            <StyledDate>
               {parseLocalDate(date, lang)} | {timeRead}
               <FontAwesome icon={'clock'} />
            </StyledDate>
         </PosedInfo>
      </StyledWrapper>
   )
}

const PosedTitle = posed(StyledTitle)({
   open: { opacity: 1, y: 0 },
   exit: { opacity: 0, y: 8 },
})

const PosedInfo = posed(StyledInfo)({
   open: { opacity: 1, y: 0 },
   exit: { opacity: 0, y: 8 },
})

const PosedCategories = posed(StyledCategories)({
   open: { opacity: 1, y: 0 },
   exit: { opacity: 0, y: 8 },
})
